<template>
  <be-modal :id="modalId" :title="template.title" size="xl">
    <div class="p-3">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h2>{{ template.report_title }}</h2>

        <div class="d-none d-lg-flex">
          <be-button
            v-if="allowedToEdit"
            variant="outline-secondary"
            :href="url(`/reports/templates/${template.id}/edit`)"
          >
            {{ $t("buttons.titles.edit") }}
          </be-button>

          <be-button
            v-if="allowedToUse"
            variant="primary"
            :href="url(`/reports/template_applications/${template.id}`)"
            data-method="patch"
            :data-confirm="
              $t('models.report_template.confirm_use_for_report_w_title', {
                title: template.title,
              })
            "
            :data-confirm-button-text="$t('buttons.titles.create')"
          >
            {{ $t("models.report_template.use_for_report") }}
          </be-button>
        </div>
      </div>

      <report-view :report="template" mode="template" />
    </div>

    <template #footer="{ close }">
      <be-button variant="light" @click="close">
        {{ $t("buttons.titles.close") }}
      </be-button>

      <be-button
        v-if="allowedToUse"
        variant="primary"
        :href="url(`/reports/template_applications/${template.id}`)"
        data-method="patch"
        :data-confirm="
          $t('models.report_template.confirm_use_for_report_w_title', {
            title: template.title,
          })
        "
        :data-confirm-button-text="$t('buttons.titles.create')"
      >
        {{ $t("models.report_template.use_for_report") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },

    template: {
      type: Object,
      required: true,
    },

    allowedToUse: {
      type: Boolean,
      required: true,
    },

    allowedToEdit: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
